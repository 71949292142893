import { useState } from 'react';
import type { NextPage } from 'next';
import { useMutation } from '@apollo/client';
import { SIGN_IN } from '~/client/apollo/mutations';
import Router from 'next/router';
import { GetServerSideProps } from 'next';
import { SessionStorage, redisSessionClient, redisAuthClient } from '@quotalogic/session';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import { AccountLayout } from '@quotalogic/widgets/Layouts/AccountLayout';
import { LoginForm } from '@quotalogic/widgets/AccountForms/Register/LoginForm';
import { DeletedAccountForm } from '@quotalogic/widgets/AccountForms/DeletedAccountForm';
import { User } from '@quotalogic/gateway/types';

export const getServerSideProps: GetServerSideProps = async ({ req, locale }) => {
  const uuid = req.cookies?.auth;
  const appName = process.env.APP_NAME;

  if (uuid) {
    const session = new SessionStorage(redisSessionClient, redisAuthClient);
    const payload = await session.getSession(uuid);

    if (payload) {
      return {
        redirect: {
          permanent: false,
          destination: '/',
        },
      };
    }
  }

  return {
    props: {
      ...(locale ? await serverSideTranslations(locale) : {}),
      appName,
    },
  };
};

type SignIn = (email: string, password: string) => void;

const Index: NextPage<{ appName: string }> = ({ appName }) => {
  const [login, { error, loading }] = useMutation<{ signIn: User }>(SIGN_IN, { errorPolicy: 'all' });
  const [userEmail, setUserEmail] = useState<string | null>(null);

  const signIn: SignIn = async (email, password) => {
    const { data: loginData, errors } = await login({ variables: { email, password } });

    if (errors?.some((err) => err?.message === 'User is disabled')) {
      setUserEmail(email);
    }

    if (loginData?.signIn) {
      // eslint-disable-next-line no-restricted-globals
      await Router.push(`/${Router.locale}`, location, { locale: false });
    }
  };

  return (
    <AccountLayout
      name={appName}
      website="quotcat.ru"
    >
      {error?.message === 'User is disabled'
        ? <DeletedAccountForm email={userEmail} />
        : <LoginForm signIn={signIn} error={error} loading={loading} />}
    </AccountLayout>
  );
};

export default Index;
