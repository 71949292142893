import React, { FormEvent, FC, useState, useMemo } from 'react';
import { Input, FormField, Button } from '@quotalogic/ui';
import { useTranslation } from 'next-i18next';
import { Eye, EyeSlash } from '@gravity-ui/icons';
import { ApolloError } from '@apollo/client';
import {
  ButtonStyle, FooterLink, LinkButton, FormStyle, Link, Title, Wrapper,
} from './styles';

interface LoginFormProps {
  signIn?: (email: string, password: string) => void,
  error?: ApolloError
  loading?: boolean
}

export const LoginForm: FC<LoginFormProps> = ({ signIn, error, loading }) => {
  const { t: translation } = useTranslation('login');

  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [isShowPass, setIsShowPass] = useState(false);
  const [passError, setPassError] = useState('');
  const [mailError, setMailError] = useState('');

  const isError = useMemo(() => {
    if (error && error.message.includes('password', 1)) {
      setPassError(error.message);
      return true;
    }
    if (error && error.message.includes('email', 1)) {
      setMailError(error.message);
      return true;
    }
    return false;
  }, [error]);

  const isFormValid = useMemo(
    () =>
      (email && password),
    [email, password],
  );

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    signIn && signIn(email, password);
  };

  return (
    <Wrapper>
      <Title>
        {translation('title')}
      </Title>

      <FormStyle onSubmit={handleSubmit}>
        <FormField
          name="email"
          label={translation('label.email')}
        >
          <Input
            type="text"
            name="email"
            status={!mailError ? 'default' : 'error'}
            message={mailError || undefined}
            required
            onChange={(e) => setEmail(e)}
          />
        </FormField>

        <FormField
          name="password"
          label={translation('label.password')}
          buttons={<LinkButton href="/password-recovery">{translation('links.forgotPass')}</LinkButton>}
        >
          <Input
            type={isShowPass ? 'text' : 'password'}
            name="password"
            status={!passError ? 'default' : 'error'}
            message={passError || undefined}
            required
            onChange={(e) => setPassword(e)}
          >
            <Button
              view="ghost"
              size="small"
              iconLeft={isShowPass ? <EyeSlash /> : <Eye />}
              onClick={() => setIsShowPass(!isShowPass)}
            />
          </Input>
        </FormField>

        <ButtonStyle
          type="submit"
          disabled={!isFormValid}
          loading={loading}
        >
          {translation('button')}
        </ButtonStyle>
      </FormStyle>

      <FooterLink>
        {translation('accountNotExists')}<Link href="/register">{translation('links.sighUp')}</Link>
      </FooterLink>
    </Wrapper>
  );
};
