import styled from 'styled-components';
import { GlobalColors } from '@quotalogic/ui';
import Link from 'next/link';

export const Wrapper = styled.div`
  display: flex;
  width: 400px;
  flex-direction: column;
  align-items: center;
  padding: 24px;
  gap: 16px;
  background: ${GlobalColors.bg.main.secondary};
  border-radius: 16px;
  box-shadow: 0 2px 8px 0 #0000000A;
`;

export const Title = styled.h2`
  text-align: center;
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
  color: ${GlobalColors.text.primary};
`;

export const StyledEmail = styled.span`
  font-weight: 600;
`;

export const StyledText = styled.span`
  color: ${GlobalColors.text.secondary};
  text-align: center;
`;

export const StyledLink = styled(Link)`
  font-size: 0.857rem;
  font-weight: 500;
  color: ${GlobalColors.text.accent};
`;
