import { FC } from 'react';
import { Avatar } from '@quotalogic/ui';
import { useTranslation } from 'next-i18next';
import { StyledEmail, StyledLink, Title, Wrapper } from './styles';

type Props = {
  email: string | null
}

export const DeletedAccountForm: FC<Props> = ({ email }) => {
  const { t } = useTranslation('password-recovery');

  return (
    <Wrapper>
      <Title>{t('deletedAccount.title')}</Title>
      <Avatar isEditable={false} colorStyle="error" />
      <StyledEmail>{email}</StyledEmail>
      {/* <StyledText>{t('deletedAccount.text')}</StyledText> */}
      {/* <Button>{t('deletedAccount.button')}</Button> */}
      <StyledLink href="/register">{t('deletedAccount.registerLink')}</StyledLink>
    </Wrapper>
  );
};
